html, body, #app, #app>div, #root, #stage {
  height: 100%;
  background-color: black;
 /* background: url(lib/img/backMenu.jpg) no-repeat center center fixed;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  */
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: black;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

.nav-link.active {
  font-style: italic;
  font-weight: bold;
}
.buttonTable{
	width: 50px;
	padding: 2px !important;
	vertical-align: inherit !important;
	text-align: center;
}

/* styles.css */
.react-switch {
  vertical-align: middle;
  margin-left: 4px;
}

.btn{
  margin: 0px !important;
}

a{
  color: inherit !important;
}


/*\
|*|  :: Bootstrap Switch Button ::
|*|
|*|  Bootstrap Switch Button (React)
|*|  https://github.com/gitbrent/bootstrap-switch-button-react
|*|
|*|  This library is released under the MIT Public License (MIT)
|*|
|*|  Bootstrap Switch Button (C) 2019-present Brent Ely (https://github.com/gitbrent)
|*|
|*|  Permission is hereby granted, free of charge, to any person obtaining a copy
|*|  of this software and associated documentation files (the "Software"), to deal
|*|  in the Software without restriction, including without limitation the rights
|*|  to use, copy, modify, merge, publish, distribute, sublicense, and/or sell
|*|  copies of the Software, and to permit persons to whom the Software is
|*|  furnished to do so, subject to the following conditions:
|*|
|*|  The above copyright notice and this permission notice shall be included in all
|*|  copies or substantial portions of the Software.
|*|
|*|  THE SOFTWARE IS PROVIDED "AS IS", WITHOUT WARRANTY OF ANY KIND, EXPRESS OR
|*|  IMPLIED, INCLUDING BUT NOT LIMITED TO THE WARRANTIES OF MERCHANTABILITY,
|*|  FITNESS FOR A PARTICULAR PURPOSE AND NONINFRINGEMENT. IN NO EVENT SHALL THE
|*|  AUTHORS OR COPYRIGHT HOLDERS BE LIABLE FOR ANY CLAIM, DAMAGES OR OTHER
|*|  LIABILITY, WHETHER IN AN ACTION OF CONTRACT, TORT OR OTHERWISE, ARISING FROM,
|*|  OUT OF OR IN CONNECTION WITH THE SOFTWARE OR THE USE OR OTHER DEALINGS IN THE
|*|  SOFTWARE.
\*/

.btn-group-xs > .btn, .btn-xs {
	padding: .35rem .4rem .25rem .4rem;
	font-size: .875rem;
	line-height: .5;
	border-radius: .2rem;
}

.checkbox label .switch, .checkbox-inline .switch {
	margin-left: 0px;
	margin-right: 0px;
}

.switch {
	position: relative;
	overflow: hidden;
}
.switch.btn.btn-light, .switch.btn.btn-outline-light {
	border-color: rgba(0, 0, 0, .15); /* Add a border so switch is delineated */
}
.switch input[type="checkbox"] {
	display: none;
}
.switch-group {
	position: absolute;
	width: 200%;
	top: 0;
	bottom: 0;
	left: 0;
	transition: left 0.35s;
	-webkit-transition: left 0.35s;
	-moz-user-select: none;
	-webkit-user-select: none;
}
.switch.off .switch-group {
	left: -100%;
}
.switch-on {
	position: absolute;
	top: 0;
	bottom: 0;
	left: 0;
	right: 50%;
	margin: 0;
	border: 0;
	border-radius: 0;
}
.switch-off {
	position: absolute;
	top: 0;
	bottom: 0;
	left: 50%;
	right: 0;
	margin: 0;
	border: 0;
	border-radius: 0;
	box-shadow: none;
}
.switch-handle {
	position: relative;
	margin: 0 auto;
	padding-top: 0px;
	padding-bottom: 0px;
	height: 100%;
	width: 0px;
	border-width: 0 1px;
	background-color: #fff;
}

.switch.btn-outline-primary .switch-handle {
	background-color: var(--primary);
	border-color: var(--primary);
}
.switch.btn-outline-secondary .switch-handle {
	background-color: var(--secondary);
	border-color: var(--secondary);
}
.switch.btn-outline-success .switch-handle {
	background-color: var(--success);
	border-color: var(--success);
}
.switch.btn-outline-danger .switch-handle {
	background-color: var(--danger);
	border-color: var(--danger);
}
.switch.btn-outline-warning .switch-handle {
	background-color: var(--warning);
	border-color: var(--warning);
}
.switch.btn-outline-info .switch-handle {
	background-color: var(--info);
	border-color: var(--info);
}
.switch.btn-outline-light .switch-handle {
	background-color: var(--light);
	border-color: var(--light);
}
.switch.btn-outline-dark .switch-handle {
	background-color: var(--dark);
	border-color: var(--dark);
}
.switch[class*="btn-outline"]:hover .switch-handle {
	background-color: var(--light);
	opacity: 0.5;
}

/* NOTE: Must come first, so classes below override as needed */
/* bootstrap-4.x .form-control {height} values used as `min-height` to mirror output sizes */

.switch.btn { min-width: 3.7rem; min-height: calc(1.5em + .75rem + 2px); }
.switch-on.btn { padding-right: 1.5rem; }
.switch-off.btn { padding-left: 1.5rem; }

.switch.btn-lg { min-width: 5rem; line-height: 1.5; min-height: calc(1.5em + 1rem + 2px); }
.switch-on.btn-lg { padding-right: 2rem; }
.switch-off.btn-lg { padding-left: 2rem; }
.switch-handle.btn-lg { width: 2.5rem; }

.switch.btn-sm { min-width: 3.25rem; min-height: calc(1.5em + .5rem + 2px); }
.switch-on.btn-sm { padding-right: 1rem; }
.switch-off.btn-sm { padding-left: 1rem; }

/* `xs` (bootstrap-3.3 - .btn - h:22px) */
.switch.btn-xs { min-width: 3.125rem; min-height: 1.375rem; }
.switch-on.btn-xs { padding-right: .8rem; }
.switch-off.btn-xs { padding-left: .8rem; }


/* React-Toggle */

.react-toggle {
	touch-action: pan-x;

	display: inline-block;
	position: relative;
	cursor: pointer;
	background-color: transparent;
	border: 0;
	padding: 0;

	-webkit-touch-callout: none;
	-webkit-user-select: none;
	-khtml-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;

	-webkit-tap-highlight-color: rgba(0,0,0,0);
	-webkit-tap-highlight-color: transparent;
  }

  .react-toggle-screenreader-only {
	border: 0;
	clip: rect(0 0 0 0);
	height: 1px;
	margin: -1px;
	overflow: hidden;
	padding: 0;
	position: absolute;
	width: 1px;
  }

  .react-toggle--disabled {
	cursor: not-allowed;
	opacity: 0.5;
	-webkit-transition: opacity 0.25s;
	transition: opacity 0.25s;
  }

  .react-toggle-track {
	width: 50px;
	height: 24px;
	padding: 0;
	border-radius: 30px;
	background-color: #4D4D4D;
	-webkit-transition: all 0.2s ease;
	-moz-transition: all 0.2s ease;
	transition: all 0.2s ease;
  }

  .react-toggle:hover:not(.react-toggle--disabled) .react-toggle-track {
	background-color: #000000;
  }

  .react-toggle--checked .react-toggle-track {
	background-color: #19AB27;
  }

  .react-toggle--checked:hover:not(.react-toggle--disabled) .react-toggle-track {
	background-color: #128D15;
  }

  .react-toggle-track-check {
	position: absolute;
	width: 14px;
	height: 10px;
	top: 0px;
	bottom: 0px;
	margin-top: auto;
	margin-bottom: auto;
	line-height: 0;
	left: 8px;
	opacity: 0;
	-webkit-transition: opacity 0.25s ease;
	-moz-transition: opacity 0.25s ease;
	transition: opacity 0.25s ease;
  }

  .react-toggle--checked .react-toggle-track-check {
	opacity: 1;
	-webkit-transition: opacity 0.25s ease;
	-moz-transition: opacity 0.25s ease;
	transition: opacity 0.25s ease;
  }

  .react-toggle-track-x {
	position: absolute;
	width: 10px;
	height: 10px;
	top: 0px;
	bottom: 0px;
	margin-top: auto;
	margin-bottom: auto;
	line-height: 0;
	right: 10px;
	opacity: 1;
	-webkit-transition: opacity 0.25s ease;
	-moz-transition: opacity 0.25s ease;
	transition: opacity 0.25s ease;
  }

  .react-toggle--checked .react-toggle-track-x {
	opacity: 0;
  }

  .react-toggle-thumb {
	transition: all 0.5s cubic-bezier(0.23, 1, 0.32, 1) 0ms;
	position: absolute;
	top: 1px;
	left: 1px;
	width: 22px;
	height: 22px;
	border: 1px solid #4D4D4D;
	border-radius: 50%;
	background-color: #FAFAFA;

	-webkit-box-sizing: border-box;
	-moz-box-sizing: border-box;
	box-sizing: border-box;

	-webkit-transition: all 0.25s ease;
	-moz-transition: all 0.25s ease;
	transition: all 0.25s ease;
  }

  .react-toggle--checked .react-toggle-thumb {
	left: 27px;
	border-color: #19AB27;
  }

  .react-toggle--focus .react-toggle-thumb {
	-webkit-box-shadow: 0px 0px 3px 2px #0099E0;
	-moz-box-shadow: 0px 0px 3px 2px #0099E0;
	box-shadow: 0px 0px 2px 3px #0099E0;
  }

  .react-toggle:active:not(.react-toggle--disabled) .react-toggle-thumb {
	-webkit-box-shadow: 0px 0px 5px 5px #0099E0;
	-moz-box-shadow: 0px 0px 5px 5px #0099E0;
	box-shadow: 0px 0px 5px 5px #0099E0;
  }

  /* React-Toggle */

.number-input input[type="number"] {
	-webkit-appearance: textfield;
	  -moz-appearance: textfield;
			appearance: textfield;
}
.number-input input[type=number]::-webkit-inner-spin-button,
.number-input input[type=number]::-webkit-outer-spin-button {
-webkit-appearance: none;
}
.number-input button {
-webkit-appearance: none;
background-color: transparent;
border: none;
align-items: center;
justify-content: center;
cursor: pointer;
margin: 0;
position: relative;
}
.number-input button:before,
.number-input button:after {
display: inline-block;
position: absolute;
content: '';
height: 2px;
transform: translate(-50%, -50%);
}
.number-input button.plus:after {
transform: translate(-50%, -50%) rotate(90deg);
}
.number-input input[type=number] {
text-align: center;
}
.number-input.number-input input[type=number] {
max-width: 4rem;
padding: .5rem;
border: 0px solid #ced4da;
border-width: 0 1px;
font-size: 1rem;
height: 2rem;
color: #495057;
}

 /* Gradient transparent - color - transparent */

hr.style-two {
    border: 0;
    height: 1px;
    background-image: linear-gradient(to right, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.75), rgba(0, 0, 0, 0));
}

@media only screen and (max-width: 1400px) {
	.container {
		max-width: 1340px;
	}
}

.MuiDrawer-paperAnchorLeft{
/*	background-image: url("lib/img/backMenu.jpg");
	background-position: left;
	background-repeat: no-repeat;
	background-size: cover;
	color: #fff !important;
	*/
	background: none !important;
}
.MuiList-root{
	/*background-color: #000000d5 !important;*/
}
.MainContainer{
/*	background-image: url("lib/img/bgTeste.png");
	background-position: center;
	background-repeat: no-repeat;
	background-size: cover;
*/
	background-color: #fff;
	padding: 25px 10px 40px 10px !important;
	font-weight: bolder;
}
.MainContainerB{
/*	background-image: url("lib/img/bgTeste.png");
	background-position: center;
	background-repeat: no-repeat;
	background-size: cover;
*/
	background-color: #fff;
	padding: 0px 10px 40px 10px !important;
	font-weight: bolder;
}

.MuiListItemIcon-root {
	color: rgba(255, 254, 254) !important;
	z-index: 2
}

.TitleContainer{
    border: 3px solid #dadada;
	border-radius: 7px;
	outline: none;
    border-color: #35bce4;
	box-shadow: 0 0 10px #35bce4;
	background-color: "transparent";
	color: #fff;
}

.MuiFab-primary {
    background-color: #2a98b8 !important;
}

.MuiFab-primary[aria-expanded="true"]{
	background-color: red !important;
}

.modal-backdrop.show {
	opacity: .8;
}

.Rotulo{
	background-color: white !important;
	padding: 2px;
}

.modal-backdrop.show {
	opacity: .9 !important;
}

.Toastify__toast-body {
	text-align: left !important;
}

.isLoading.modal-dialog {
	width: 100% !important;
	height: 100% !important;
	margin: 0 !important;
	padding: 0 !important;
	max-width:none !important;

}
.modal-content {
	height: 100% !important;
}

.ExpansionPainelData {
	background-color: #ffffffe8 !important;
	margin: 0em !important;
}

#panel1bh-header{
	background-color: #7cccff7a !important;
}

.ui.search>.results {
	width: 100%;
}
.ui.input{
	width: 100%;
}
.list-unstyled{
	margin: 0px !important;
}
.MuiStepLabel-label.MuiStepLabel-completed {
    color: rgb(81, 170, 50) !important;
}
.MuiStepIcon-root.MuiStepIcon-completed{
	color: #20aa31 !important;
}
.NoMargin .MuiExpansionPanelSummary-content{
	margin: 0em !important;
}
.MuiBox-root{
	padding: 5px !important;
}
#labelInterna{
	color: #575757 !important;
}