*{font-family: 'Roboto', sans-serif;}

@keyframes click-wave {
  0% {
    height: 20px;
    width: 20px;
    opacity: 0.35;
    position: relative;
  }
  100% {
    height: 200px;
    width: 200px;
    margin-left: -80px;
    margin-top: -80px;
    opacity: 0;
  }
}

.option-input {
  -webkit-appearance: none;
  -moz-appearance: none;
  -ms-appearance: none;
  -o-appearance: none;
  appearance: none;
  position: relative;
  top: 4.33333px;
  right: 0;
  bottom: 0;
  left: 0;
  height: 20px;
  width: 20px;
  transition: all 0.15s ease-out 0s;
  background: #cbd1d8;
  border: none;
  color: #fff;
  cursor: pointer;
  display: inline-block;
  margin-right: 0.5rem;
  outline: none;
  position: relative;
  z-index: 1000;
}
.option-input:hover {
  background: #9faab7;
}
.option-input:checked {
  background: #40e0d0;
}
.option-input:checked::before {
  height: 20px;
  width: 20px;
  position: absolute;
  content: '✔';
  display: inline-block;
  font-size: 16.66667px;
  text-align: center;
  line-height: 20px;
}
.option-input:checked::after {
  -webkit-animation: click-wave 0.65s;
  -moz-animation: click-wave 0.65s;
  animation: click-wave 0.65s;
  background: #40e0d0;
  content: '';
  display: block;
  position: relative;
  z-index: 100;
}
.option-input.radio {
  border-radius: 50%;
}
.option-input.radio::after {
  border-radius: 50%;
}

ul {
    list-style-type: none;
}

.cltDanger, .cltDanger ul, .cltDanger li, .cltPrimary, .cltPrimary ul, .cltPrimary li {
  position: relative;
}

.cltDanger ul, .cltPrimary ul {
  list-style: none;
  padding-left: 32px;
}

.cltDanger li::before, .cltDanger li::after, .cltPrimary li::before, .cltPrimary li::after {
  content: "";
  position: absolute;
  left: -12px;
}

.cltDanger li::before {
    border-top: 1px solid #ff3547;
    top: 13px;
    width: 15px;
    height: 0;
}
.cltPrimary li::before {
  border-top: 1px solid #4285f4;
  top: 13px;
  width: 15px;
  height: 0;
}

.cltDanger li::after {
    border-left: 1px solid #ff3547;
    height: 35px;
    width: 0px;
    top: -7px;
}
.cltPrimary li::after {
  border-left: 1px solid #4285f4;
  height: 35px;
  width: 0px;
  top: -7px;
}

.cltDanger ul > li:last-child::after, .cltPrimary ul > li:last-child::after {
  height: 20px;
}

#ModalActionFooter{
  justify-content: center !important;
}

.modal-title{
  width: 100% !important;
}
